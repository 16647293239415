import React, { useContext } from 'react';
import { Formik, FieldArray } from 'formik';
import { useSnackbar } from 'notistack';
import { Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StepTitle from '../../Common/PageLayout/StepTitle';
import StepWrapper from '../../Common/PageLayout/StepWrapper';
import WrappedDivider from '../../Common/WrappedDivider/WrappedDivider';
import FormikTextField from '../../Common/Formik/FormikTextField';
import { Context, actionTypes } from '../../../Stores/EventInfoStore';
import { methods, useApi } from '../../../Hooks/useApi';
import { validationSchema } from './FAQValidationSchema';
import Grid from '@mui/material/Unstable_Grid2';
const useStyles = makeStyles((theme) => ({
  customMargin: {
    marginBottom: theme.spacing(1),
  },
  buttonsGrid: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    backgroundColor: '#FFFFFF',
    zIndex: 1,
    paddingTop: `${theme.spacing(2)} !important`,
    paddingBottom: `${theme.spacing(2)} !important`,
    paddingRight: `15% !important`,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
  },
  editMargin: {
    marginBottom: theme.spacing(7),
  },
}));

const WHERE_WHEN = 'whereWhen';
const SOMEONE_COLLECT_PACK = 'someoneCollectPack';
const HOW_TO_ARRIVE = 'howToArrive';
const CHANGE_DISTANCE = 'changeDistance';
const REFUND_POLICY = 'refundPolicy';
const CUSTOM = 'custom';

const presetFAQs = [
  {
    id: WHERE_WHEN,
    title: 'When and where can I collect my bib/race pack?',
  },
  {
    id: SOMEONE_COLLECT_PACK,
    title: 'Can someone collect my race pack for me?',
  },
  { id: HOW_TO_ARRIVE, title: 'How do I get to the race location?' },
  {
    id: CHANGE_DISTANCE,
    title: 'Can I change the distance after I purchase a ticket?',
  },
  {
    id: REFUND_POLICY,
    title: 'What is the cancelation/refund policy for this event?',
  },
];

const getFAQUrl = (eventId) => `/faqs/${eventId}`;

const FAQs = () => {
  const classes = useStyles();
  const [eventInfo, dispatch] = useContext(Context);
  const { faqs } = eventInfo;
  const { eventId } = eventInfo.event;
  const { executeApiCall } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const submitFAQs = async (values) => {
    const customs = values.customs
      ?.filter((q) => q.answer)
      ?.map((q) => ({
        questionType: CUSTOM,
        question: q.question,
        answer: q.answer,
      }));

    let presets = [];
    for (const [key, value] of Object.entries(values)) {
      if (key === 'customs' || !value) continue;
      presets.push({ questionType: key, answer: value });
    }
    const faqs = [...presets, ...customs];
    try {
      await executeApiCall(getFAQUrl(eventId), methods.put, { faqs });
      dispatch({ type: actionTypes.faqs_set, payload: faqs });
      enqueueSnackbar('Your FAQs have been successfully updated', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const getInitialAnswer = (question) =>
    faqs.find((x) => x.questionType === question)?.answer;

  const getInitialCustoms = () => {
    const customs = faqs.filter((x) => x.questionType === CUSTOM);
    let initCustoms = [];
    for (let i = 0; i < 6; i++) {
      if (customs?.[i])
        initCustoms.push({
          question: customs[i].question,
          answer: customs[i].answer,
        });
      else initCustoms.push({ question: '', answer: '' });
    }
    return initCustoms;
  };

  return (
    <>
      <StepTitle
        title="FAQs (optional)"
        subtitle={`Answer the most Frequently Asked Questions to provide as much information as possible about your event. 
      The questions and answers will be displayed on your event page.`}
      />
      <StepWrapper className={classes.editMargin}>
        <Formik
          initialValues={{
            [WHERE_WHEN]: getInitialAnswer(WHERE_WHEN) ?? '',
            [SOMEONE_COLLECT_PACK]:
              getInitialAnswer(SOMEONE_COLLECT_PACK) ?? '',
            [HOW_TO_ARRIVE]: getInitialAnswer(HOW_TO_ARRIVE) ?? '',
            [CHANGE_DISTANCE]: getInitialAnswer(CHANGE_DISTANCE) ?? '',
            [REFUND_POLICY]: getInitialAnswer(REFUND_POLICY) ?? '',
            customs: getInitialCustoms(),
          }}
          onSubmit={async (values, { resetForm }) => {
            await submitFAQs(values);
            resetForm({ values });
          }}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, isSubmitting, values, dirty }) => (
            <>
              <Grid container spacing={0} justifyContent="center">
                <Grid xs={12} sm={8} container spacing={3}>
                  <Grid xs={12}>
                    <Typography gutterBottom variant="h5" color="initial">
                      Most frequently asked questions
                    </Typography>
                    <Typography gutterBottom>
                      The question and answer will be displayed only if the
                      answer is filled out.
                    </Typography>
                  </Grid>
                  {presetFAQs.map((q) => (
                    <Grid xs={12} key={q.id}>
                      <FormikTextField
                        variant="outlined"
                        fullWidth
                        label={q.title}
                        name={q.id}
                        multiline
                        rows={3}
                      />
                    </Grid>
                  ))}
                  <WrappedDivider />
                  <Grid xs={12}>
                    <Typography gutterBottom variant="h5" color="initial">
                      Custom FAQs
                    </Typography>
                    <Typography gutterBottom>
                      You can add up to 6 additional FAQs.
                    </Typography>
                  </Grid>
                  <FieldArray
                    name="customs"
                    render={() =>
                      values.customs.map((x, i) => (
                        <Grid key={`custom-${i}`} xs={12}>
                          <FormikTextField
                            label="Question"
                            name={`customs[${i}].question`}
                            fullWidth
                            className={classes.customMargin}
                          />
                          <FormikTextField
                            label="Answer for the question above"
                            name={`customs[${i}].answer`}
                            fullWidth
                            multiline
                            rows={3}
                          />
                        </Grid>
                      ))
                    }
                  />
                  <Grid
                    container
                    justifyContent="flex-end"
                    className={classes.buttonsGrid}
                    spacing={0}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={!dirty || isSubmitting}
                    >
                      {isSubmitting ? 'Saving FAQs…' : 'Save FAQs'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </StepWrapper>
    </>
  );
};

export default FAQs;
