import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  Box,
  Typography,
  InputAdornment,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateRange as DateRangeIcon } from '@mui/icons-material';
import { Chart } from 'react-google-charts';
import { wmPalette } from '../../../Theme/WorldSportsGroupTheme';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  subMonths,
  subWeeks,
  format,
  startOfWeek,
  endOfWeek,
  startOfYesterday,
  endOfYesterday,
  startOfDay,
  endOfDay,
  isBefore,
} from 'date-fns';
import { useApi, methods } from '../../../Hooks/useApi';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import SalesModal from './SalesModal';
import KpiCard from './KpiCard';
import TimefilterCard from './TimefilterCard';
import { formatPrice, formatApiDateTime } from '../../../Format';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Skeleton from '@mui/material/Skeleton';
import { onKeyDown } from '../../../Utils/dateUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    root: {
      stepContent: {
        padding: theme.spacing(4),
        [theme.breakpoints.down('lg')]: {
          padding: theme.spacing(1),
        },
      },
    },
  },
  overviewCard: {
    width: '100%',
    padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(
      6
    )} ${theme.spacing(4)}`,
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
    },
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  pointer: {
    cursor: 'pointer',
  },
  timefilterBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'flex-start',
      width: '50vw',
      overflow: 'hidden',
      overflowX: 'scroll',
      overflowY: 'hidden',
      whiteSpace: 'nowrap',
      overflowScrolling: 'touch',
      WebkitOverflowScrolling: 'touch',
    },
    [theme.breakpoints.down('md')]: {
      width: '60vw',
    },
  },
  chartWithOverlay: {
    position: 'relative',
    width: '100%',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  overlay: {
    width: '500px',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '100px',
    left: '50%',
    transform: 'translateX(-50%)',
    whiteSpace: 'pre',
    overflowWrap: 'break-word',
    [theme.breakpoints.down('lg')]: {
      width: '110%',
      height: '300px',
    },
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: wmPalette.blue[100],
  },
  hidden: {
    display: 'none',
  },
  visible: {
    display: 'block',
  },
  customBox: {
    width: '100%',
  },
  customBoxVisible: {
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(4),
      animation: `$openMobile 500ms forwards`,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
      animation: `$openDesktop 500ms forwards`,
    },
  },
  customBoxInvisible: {
    [theme.breakpoints.down('lg')]: {
      animation: `$closeMobile 500ms forwards`,
    },
    [theme.breakpoints.up('md')]: {
      animation: `$closeDesktop 500ms forwards`,
    },
  },
  '@keyframes openDesktop': {
    '0%': {
      height: '0px',
      opacity: 0,
      visibility: 'hidden',
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      height: '40px',
      visibility: 'visible',
    },
  },
  '@keyframes closeDesktop': {
    '0%': {
      height: '40px',
      opacity: 1,
      visibility: 'visible',
    },
    '50%': {
      opacity: 0,
    },
    '100%': {
      height: '0px',
      opacity: 0,
      visibility: 'hidden',
    },
  },
  '@keyframes openMobile': {
    '0%': {
      height: '0px',
      opacity: 0,
      visibility: 'hidden',
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      height: '140px',
      visibility: 'visible',
    },
  },
  '@keyframes closeMobile': {
    '0%': {
      height: '140px',
      opacity: 1,
      visibility: 'visible',
    },
    '50%': {
      opacity: 0,
    },
    '100%': {
      height: '0px',
      opacity: 0,
      visibility: 'hidden',
    },
  },
  blurredDiv: {
    filter: 'blur(5px)',
  },
  paddingZero: {
    padding: 0,
  },
}));

const beautifyDate = (date) => format(new Date(date), 'PPP');

const OverviewGraph = ({
  eventId,
  editionId,
  eventInfo,
  startDate,
  endDate,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { executeApiCall, apiLoading } = useApi();
  const currency = eventInfo.basicInfo.eventCurrency;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const today = new Date();
  const [activeCard, setActiveCard] = useState(0);
  const [activeTimefilter, setActiveTimefilter] = useState(0);
  const [showGraph, setShowGraph] = useState(true);

  // Filters
  const [category, setCategory] = useState('participants');
  const [from, setFrom] = useState(startDate);
  const [to, setTo] = useState(endDate);

  const [totalParticipants, setTotalParticipants] = useState(0);
  const [totalAddons, setTotalAddons] = useState(0);
  const [totalTickets, setTotalTickets] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [hasDonations, setHasDonations] = useState(false);

  const kpiCards = [
    ['Participants', `${totalParticipants}`],
    ['Tickets', `${formatPrice(totalTickets, currency)}`],
    [
      `Add-ons${hasDonations ? '/Donations' : ''}`,
      `${formatPrice(totalAddons, currency)}`,
    ],
    ['Revenue', `${formatPrice(totalRevenue, currency)}`],
  ];

  const [customBox, setCustomBox] = useState(false);

  // empty state
  const [noData, setNoData] = useState(true);
  const [noDataCase, setNoDataCase] = useState(5);

  const [openSalesModal, setOpenSalesModal] = useState(false);

  const dataURL = (eventId, editionId, category) =>
    `/eventstats/${eventId}/races/${editionId}/orders/${category}?from=${from}&to=${to}&currency=${currency}`;

  const isMounted = useRef(false);
  const [data, setData] = useState([
    ['Date', 'Participants'],
    [today, 0],
  ]);

  const toggleVisibility = () => {
    setShowGraph(!showGraph);
  };

  const onKpiCardClick = (id) => {
    setActiveCard(id);
    switch (id) {
      case 0:
        setCategory('participants');
        break;
      case 1:
        setCategory('tickets');
        break;
      case 2:
        setCategory('addons');
        break;
      case 3:
        setCategory('value');
        break;
      default:
        break;
    }
  };

  const onTimefilterCardClick = (id) => {
    setActiveTimefilter(id);
    setCustomBox(false);

    switch (id) {
      case 0:
        // All time
        setFrom(startDate);
        setTo(endDate);
        break;
      case 1:
        // this month
        setFrom(formatApiDateTime(subMonths(today, 1)));
        setTo(formatApiDateTime(today));
        break;
      case 2:
        // Last week
        setFrom(
          formatApiDateTime(
            subWeeks(startOfWeek(today, { weekStartsOn: 1 }), 1)
          )
        );
        setTo(
          formatApiDateTime(subWeeks(endOfWeek(today, { weekStartsOn: 1 }), 1))
        );
        break;
      case 3:
        // This week
        setFrom(formatApiDateTime(startOfWeek(today, { weekStartsOn: 1 }), 1));
        setTo(formatApiDateTime(today));
        break;
      case 4:
        // Yesterday
        setFrom(formatApiDateTime(startOfYesterday()));
        setTo(formatApiDateTime(endOfYesterday()));
        break;
      case 5:
        // Today
        setFrom(formatApiDateTime(startOfDay(today)));
        setTo(formatApiDateTime(endOfDay(today)));
        break;
      case 6:
        setCustomBox(true);
        break;
      default:
        break;
    }
  };

  const emptyState = {
    1: {
      participants: {
        title: 'No participants yet',
        text: 'This event has not been published.',
        action: '',
      },
      tickets: {
        title: 'Tickets for this event are not yet on sale',
        text: 'This event has not been published.',
        action: '',
      },
      addons: {
        title: 'Add-ons for this event are not yet on sale',
        text: 'This event has not been published.',
        action: '',
      },
      value: {
        title: 'No revenue yet',
        text: 'This event has not been published. ',
        action: '',
      },
    },
    2: {
      participants: {
        title: 'No participants yet',
        text: 'This event is not activated for sales.',
        action: 'Change the first day of sales',
      },
      tickets: {
        title: 'No ticks have been sold yet',
        text: 'This event is not activated for sales.',
        action: 'Change the first day of sales',
      },
      addons: {
        title: 'No add-ons have been sold yet',
        text: 'This event is not activated for sales.',
        action: 'Change the first day of sales',
      },
      value: {
        title: 'No revenue yet',
        text: 'This event is not activated for sales.',
        action: 'Change the first day of sales',
      },
    },
    3: {
      participants: {
        title: 'No participants yet',
        text: 'Check out 5 proven ways to drive sales.',
        action: '5 proven ways to drive sales',
      },
      tickets: {
        title: 'No ticks have been sold yet',
        text: 'Check out 5 proven ways to drive sales.',
        action: '5 proven ways to drive sales',
      },
      addons: {
        title: 'No add-ons have been sold yet',
        text: 'Check out 5 proven ways to drive sales.',
        action: '5 proven ways to drive sales',
      },
      value: {
        title: 'No revenue yet',
        text: 'Check out 5 proven ways to drive sales.',
        action: '5 proven ways to drive sales',
      },
    },
    4: {
      participants: {
        title: `No participants between \n ${beautifyDate(from)} ${
          isMobile ? '\n' : ''
        } - ${isMobile ? '\n' : ''} ${beautifyDate(to)} `,
        text: 'Please try different date ranges.',
        action: '5 proven ways to drive sales',
      },
      tickets: {
        title: `No tickets have been sold between \n ${beautifyDate(from)} ${
          isMobile ? '\n' : ''
        } - ${isMobile ? '\n' : ''} ${beautifyDate(to)}`,
        text: 'Please try different date ranges.',
        action: '5 proven ways to drive sales',
      },
      addons: {
        title: `No add-ons have been sold between \n ${beautifyDate(from)} ${
          isMobile ? '\n' : ''
        } - ${isMobile ? '\n' : ''} ${beautifyDate(to)}`,
        text: 'Please try different date ranges.',
        action: '5 proven ways to drive sales',
      },
      value: {
        title: `No revenue between \n ${beautifyDate(from)} ${
          isMobile ? '\n' : ''
        } - ${isMobile ? '\n' : ''} ${beautifyDate(to)}`,
        text: 'Please try different date ranges.',
        action: '5 proven ways to drive sales',
      },
    },
    5: {
      participants: {
        title: 'Loading',
        text: 'Your data is loading...',
        action: '',
      },
      tickets: {
        title: 'Loading',
        text: 'Your data is loading...',
        action: '',
      },
      addons: {
        title: 'Loading',
        text: 'Your data is loading...',
        action: '',
      },
      value: {
        title: 'Loading',
        text: 'Your data is loading...',
        action: '',
      },
    },
  };

  const emptyStateModal = () => {
    // Case 1: Publish: NO & Sales: INACTIVE
    if (eventInfo.underReview === true) {
      setNoDataCase(1);
    } else if (
      isBefore(today, new Date(eventInfo.basicInfo.eventSaleStartDate))
    ) {
      // Case 2: Publish: YES, sales: INACTIVE
      setNoDataCase(2);
    } else {
      // Case 4: Publish: YES, sales: ACTIVE
      setNoDataCase(4);
    }
    setNoData(true);
  };

  const fetchData = async () => {
    try {
      const baseData = await executeApiCall(
        dataURL(eventId, editionId, category),
        methods.get
      );
      let result = [];
      if (category === 'addons' && baseData.series.length > 0) {
        const monthToValueMapping = baseData.series
          .filter((v) => v.name !== 'Donations')
          .map((s) => s.values)
          .flat()
          .reduce((acc, value) => {
            if (!acc[value.label]) acc[value.label] = 0;
            acc[value.label] += value.val;
            return acc;
          }, {});
        const arr = Object.entries(monthToValueMapping).map(([label, val]) => [
          label,
          val,
        ]);
        result = arr;
      } else if (baseData.series.length > 0) {
        result = baseData.series[0].values.map((data) => {
          return [data.label, data.val];
        });
      }
      if (result.length === 0) {
        emptyStateModal();
        result.push(['', 0]);
      } else {
        setNoData(false);
      }
      result.unshift([
        'Date',
        category === 'value'
          ? 'Revenue '
          : category.charAt(0).toUpperCase() + category.slice(1),
      ]);
      return result;
    } catch (e) {}
  };

  const fetchAllCategories = async () => {
    try {
      const p = await executeApiCall(
        dataURL(eventId, editionId, 'participants'),
        methods.get
      );
      const t = await executeApiCall(
        dataURL(eventId, editionId, 'tickets'),
        methods.get
      );
      const a = await executeApiCall(
        dataURL(eventId, editionId, 'addons'),
        methods.get
      );
      const r = await executeApiCall(
        dataURL(eventId, editionId, 'value'),
        methods.get
      );

      setHasDonations(
        a.series.some((s) => s.name === 'Donations' && s.total && s.total !== 0)
      );

      p.series.length > 0
        ? setTotalParticipants(p.series[0].total)
        : setTotalParticipants(0);
      t.series.length > 0
        ? setTotalTickets(t.series[0].total)
        : setTotalTickets(0);
      a.series.length > 0
        ? setTotalAddons(
            a.series.reduce((a, b) => ({
              total: a.total + b.total,
            })).total
          )
        : setTotalAddons(0);
      r.series.length > 0
        ? setTotalRevenue(r.series[0].total)
        : setTotalRevenue(0);
    } catch (e) {}
  };

  useEffect(() => {
    const initialFetch = async () => {
      setData(await fetchData());
      await fetchAllCategories();
    };
    initialFetch();
  }, [from, to]);

  useEffect(() => {
    const updateGraph = async () => {
      setData(await fetchData());
    };
    isMounted.current ? updateGraph() : (isMounted.current = true);
  }, [category]);

  return (
    <>
      <Grid container xs={12} spacing={0} className={classes.paddingZero}>
        <Card className={classes.overviewCard}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            className={classes.paddingZero}
          >
            {kpiCards.map((card, i) => (
              <KpiCard
                key={i}
                cardId={i}
                activeCard={activeCard}
                onClick={onKpiCardClick}
                title={card[0]}
                number={card[1]}
                loading={apiLoading}
              />
            ))}
            {showGraph ? (
              <KeyboardArrowUpIcon
                sx={{
                  display: { xs: 'none', md: 'block' },
                }}
                className={clsx(classes.marginLeftAuto, classes.pointer)}
                onClick={toggleVisibility}
                fontSize="large"
              />
            ) : (
              <KeyboardArrowDownIcon
                className={clsx(classes.marginLeftAuto, classes.pointer)}
                onClick={toggleVisibility}
                fontSize="large"
              />
            )}
          </Grid>
          <Box className={showGraph ? classes.visible : classes.hidden}>
            <Grid>
              <Grid xs={12}>
                <Box className={classes.chartWithOverlay}>
                  {noData && noDataCase === 5 ? (
                    <>
                      <br />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={'100%'}
                        height={'380px'}
                      />
                    </>
                  ) : (
                    <div className={apiLoading ? classes.blurredDiv : ''}>
                      <Chart
                        legendToggle={false}
                        width={'100%'}
                        height={'400px'}
                        chartType="LineChart"
                        loader={
                          <>
                            <br />
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              width={'100%'}
                              height={'380px'}
                            />
                          </>
                        }
                        data={data.length > 0 ? data : []}
                        options={{
                          colors: [wmPalette.green[70]],
                          vAxis: { viewWindow: { min: 0 } },
                          pointSize: 4,
                          series: {
                            0: {
                              curveType: 'function',
                            },
                          },
                          animation: {
                            duration: 100,
                            easing: 'in',
                          },

                          chartArea: { width: '100%', height: '80%' },
                        }}
                        rootProps={{ 'data-testid': '1' }}
                      />
                      {noData && (
                        <Card className={classes.overlay}>
                          <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            className={classes.paddingZero}
                          >
                            <Grid>
                              <Typography align="center" variant="h6">
                                {emptyState[noDataCase][category].title}
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography align="center">
                                {emptyState[noDataCase][category].text}
                              </Typography>
                            </Grid>
                            <Grid>
                              <Typography
                                onClick={() => {
                                  if (noDataCase === 4) {
                                    setOpenSalesModal(true);
                                  } else {
                                    navigate(
                                      `/app/manage-event/${eventId}/${editionId}/basicInfo`
                                    );
                                  }
                                }}
                                className={classes.link}
                                align="center"
                              >
                                {emptyState[noDataCase][category].action}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Card>
                      )}
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={0} className={classes.paddingZero}>
              <Grid xs={12}>
                <Box className={classes.timefilterBox}>
                  <TimefilterCard
                    id={0}
                    activeTimefilter={activeTimefilter}
                    name="All time"
                    onClick={onTimefilterCardClick}
                  />
                  <TimefilterCard
                    id={1}
                    activeTimefilter={activeTimefilter}
                    name="This month"
                    onClick={onTimefilterCardClick}
                  />
                  <TimefilterCard
                    id={2}
                    activeTimefilter={activeTimefilter}
                    name="Last week"
                    onClick={onTimefilterCardClick}
                  />
                  <TimefilterCard
                    id={3}
                    activeTimefilter={activeTimefilter}
                    name="This week"
                    onClick={onTimefilterCardClick}
                  />
                  <TimefilterCard
                    id={4}
                    activeTimefilter={activeTimefilter}
                    name="Yesterday"
                    onClick={onTimefilterCardClick}
                  />
                  <TimefilterCard
                    id={5}
                    activeTimefilter={activeTimefilter}
                    name="Today"
                    onClick={onTimefilterCardClick}
                  />
                  <TimefilterCard
                    id={6}
                    activeTimefilter={activeTimefilter}
                    name={<DateRangeIcon />}
                    onClick={onTimefilterCardClick}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box
              className={clsx(
                classes.customBox,
                customBox
                  ? classes.customBoxVisible
                  : classes.customBoxInvisible
              )}
            >
              <Grid
                container
                direction={isMobile ? 'column' : 'row'}
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
                className={classes.paddingZero}
              >
                <Grid>
                  <DatePicker
                    size="small"
                    disableFuture
                    disableMaskedInput
                    allowKeyboardControl
                    label="From"
                    format="PPP"
                    value={from}
                    minDate={startDate}
                    onChange={(date) => {
                      if (date) setFrom(formatApiDateTime(date));
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <DateRangeIcon />
                        </InputAdornment>
                      ),
                    }}
                    renderInput={(props) => (
                      <TextField
                        fullWidth
                        onKeyDown={onKeyDown}
                        required
                        {...props}
                      />
                    )}
                  />
                </Grid>
                <Grid>
                  <Typography>-</Typography>
                </Grid>
                <Grid>
                  <DatePicker
                    size="small"
                    disableFuture
                    disableMaskedInput
                    allowKeyboardControl
                    label="To"
                    format="PPP"
                    value={to}
                    minDate={startDate}
                    onChange={(date) => {
                      if (date) setTo(formatApiDateTime(date));
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <DateRangeIcon />
                        </InputAdornment>
                      ),
                    }}
                    renderInput={(props) => (
                      <TextField
                        fullWidth
                        onKeyDown={onKeyDown}
                        required
                        {...props}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <SalesModal open={openSalesModal} handleClose={setOpenSalesModal} />
          </Box>
        </Card>
      </Grid>
    </>
  );
};

export default OverviewGraph;
